<template>
  <div class="card card-primary card-outline">
    <div class="card-header">
      <h5 class="card-title m-0">
        <i class="fa fa-list"></i> List All History
      </h5>
    </div>
    <div class="card-body">
      <div class="alert alert-light">
        <i class="fa fa-info-circle"></i> Penting
        <ul>
          <li>
            Pencarian hanya untuk kolom <code>threadid</code>,
            <code>subthreadid</code>, <code>IP</code>, <code>nama</code>,
            <code>email customer</code> dan <code>referrer</code>.
          </li>
          <li>Pencarian hanya berlaku di range tanggal yang telah dipilih.</li>
          <li>Default history untuk 3 bulan kebelakang</li>
        </ul>
      </div>
      <div>
        <!--filter & length-->
        <div class="d-flex align-items-end">
          <b-form-group label="Start Date :" class="mb-0">
            <b-form-input
              type="date"
              v-model="form.startdate"
              :value="form.startdate"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="End Date :" class="ml-1 mb-0">
            <b-form-input type="date" v-model="form.enddate"></b-form-input>
          </b-form-group>

          <b-form-group class="ml-1 mb-0" label="Keyword :">
            <b-form-input
              type="search"
              placeholder="Search"
              v-model="chat.search"
            ></b-form-input>
          </b-form-group>
          <button class="btn btn-info ml-1" @click="findRecords">Find</button>
        </div>

        <div class="d-flex justify-content-between align-items-center mt-4">
          <div class="mb-2 d-flex align-items-center">
            <div>
              <b-form-select
                :options="[5, 10, 15, 20]"
                :value="5"
                v-model="chat.perPage"
              ></b-form-select>
            </div>

            &nbsp; &nbsp;
            <div>
              <b>{{ chat.totalRows }}</b> Records
            </div>
          </div>

          <!-- pagination -->
          <b-pagination
            v-model="chat.currentPage"
            :total-rows="chat.totalRows"
            :per-page="chat.perPage"
            aria-controls="my-table"
            align="right"
          ></b-pagination>
        </div>

        <b-table
          id="my-table"
          striped
          hover
          show-empty
          :fields="chat.fields"
          :items="getChatHistory"
          :per-page="chat.perPage"
          :current-page="chat.currentPage"
        >
          <template #table-busy>
            <div class="text-center text-warning my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </template>

          <!-- id custom -->
          <template #cell(id)="data">
            {{ data.item.id }} <br /><small>sub {{ data.item.subid }}</small>
          </template>

          <!--list agent custom slot-->
          <template #cell(agents)="data">
            <li v-for="(agent, key) in data.item.agents" :key="key">
              {{ agent.agent }}
            </li>
          </template>

          <!--customer custom slot-->
          <template #cell(custname)="data">
            {{ data.item.nama }} <br />
            <small
              ><i class="fa fa-envelope-o"></i> {{ data.item.email }}</small
            >
          </template>

          <!--handle time custom slot-->
          <template #cell(handle_time)="data">
            {{ data.item.duration }} <br />
            <small> {{ data.item.start }} s/d {{ data.item.end }}</small>
          </template>

          <!-- additional custom slot-->
          <template #cell(additional)="data">
            <small
              >{{ data.item.ip }} <br />
              {{ data.item.referrer }}</small
            >
          </template>

          <!--actions-->
          <template #cell(action)="data">
            <router-link
              class="btn btn-success btn-sm"
              :to="{ path: `/panel/chat/${data.item.subidhash}?ref=history` }"
              ><i class="fa fa-eye"></i> Detail</router-link
            >
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/common/mixins/mix_helper";

export default {
  name: "History",
  mixins: [request],
  data() {
    return {
      chat: {
        records: [],
        isBusy: true,
        perPage: 5,
        currentPage: 1,
        search: null,
        totalRows: 1,
        fields: [
          {
            key: "id",
            label: "Thread",
            sortable: false,
            class: "text-center",
          },
          {
            key: "custname",
            label: "Customer",
            sortable: false,
          },
          {
            key: "agents",
            label: "Agents Handle",
            sortable: false,
          },
          {
            key: "handle_time",
            sortable: false,
          },
          {
            key: "additional",
            sortable: false,
          },
          {
            key: "action",
            label: "#",
            class: "text-center",
          },
        ],
      },
      form: {
        startdate: "",
        enddate: "",
      },
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * request data history server side
     * payload
     *  - limit : limit data show
     *  - page : for current page -1 for count offset
     *  - search : search keyword
     * --------------------------------------------------------------
     */
    getChatHistory: function (ctx, callback) {
      this.API.post(this.URL.thread.history, {
        limit: ctx.perPage,
        page: ctx.currentPage - 1,
        search: this.chat.search,
        startdate: this.form.startdate,
        enddate: this.form.enddate,
      })
        .then(({ data }) => {
          this.chat.totalRows = data.data.sum;
          callback(data.data.records);
        })
        .catch((err) => {
          this.chat.records = err;
          callback([]);
        });

      // Must return null or undefined to signal b-table that callback is being used
      return null;
    },

    /**
     * --------------------------------------------------
     * button action find
     * --------------------------------------------------
     */
    findRecords() {
      this.$root.$emit("bv::refresh::table", "my-table");
    },
  },
  computed: {
    current_date() {
      let today = new Date();
      let month =
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : today.getMonth() + 1;
      let date = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
      return today.getFullYear() + `-${month}-${date}`;
    },
    start_date() {
      let today = new Date();
      today.setMonth(today.getMonth() - 3);

      let month =
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : today.getMonth() + 1;
      let date = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
      return today.getFullYear() + `-${month}-${date}`;
    },
  },
  mounted() {
    this.$store.dispatch("breadcrumb", {
      title: "History Page",
      path: "History",
    });
    this.$store.dispatch("activeLink", this.$route.path);
    this.form.startdate = this.start_date;
    this.form.enddate = this.current_date;
  },
};
</script>

<style scoped></style>
